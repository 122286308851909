// Default
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

// Components
import getCustomComponent from './components';

function Markdown(props) {
	const components = getCustomComponent(props);
	const markdownProps = { components, className: props.className || '' };

	if (props.allowedElements) {
		markdownProps.allowedElements = props.allowedElements;
	} else if (props.disallowedElements) {
		markdownProps.disallowedElements = props.disallowedElements;
	}

	if (props.renderHTML) {
		return (
			<div
				className={props.className || ''}
				dangerouslySetInnerHTML={{ __html: props.children.replace(/\t/g, '') }}
			/>
		);
	}

	return (
		<ReactMarkdown
			{...markdownProps}
			remarkPlugins={[remarkGfm]}
			rehypePlugins={[rehypeRaw]}
		>
			{props.children.replace(/\t/g, '')}
		</ReactMarkdown>
	);
}

export default Markdown;

Markdown.propTypes = {
	useCustomList: PropTypes.bool,
	useCustomImage: PropTypes.bool,
	useCustomTable: PropTypes.bool,
	className: PropTypes.string,
	allowedElements: PropTypes.array,
	disallowedElements: PropTypes.array,
	tableTextAlignment: PropTypes.string,
	tableStyled: PropTypes.bool,
	rowBorder: PropTypes.bool,
	columnBorder: PropTypes.bool,
	renderHTML: PropTypes.bool,
};

Markdown.defaultProps = {
	useCustomTable: true,
};
