import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Components
import StyledTableTag from '@/components/widgets/styledTableTag';
import CustomAnchor from './partials/custom-anchor';
import CustomImage from './partials/custom-image';
import CustomListItem from './partials/custom-li';

const listItemValue = (nodes) => {
	if (isEmpty(nodes)) return null;

	let value, currentNode;
	for (let i = 0; i < nodes.length; i++) {
		if (value) break;

		currentNode = nodes[i];
		value =
			currentNode.value && currentNode.value !== '\n'
				? currentNode.value
				: listItemValue(currentNode.children);
	}

	return value || null;
};

const getCustomComponent = ({
	useCustomList,
	useCustomImage,
	useCustomTable,
	tableTextAlignment,
	tableStyled,
	rowBorder,
	columnBorder,
}) => ({
	p: (paragraph) => {
		const { node } = paragraph;

		if (useCustomImage && node.children[0].tagName === 'img') {
			const image = node.children[0];
			const metastring = image.properties.alt;
			const alt = metastring?.replace(/ *\{[^)]*\} */g, '');

			return <CustomImage src={image.properties.src} alt={alt} />;
		}
		return <p>{paragraph.children}</p>;
	},
	img: (imageProps) => (
		// eslint-disable-next-line @next/next/no-img-element
		<img
			{...imageProps}
			alt={imageProps.alt || ''}
			style={{ maxWidth: '100%', maxHeight: '100%' }}
		/>
	),
	ul: (unorderedList) => {
		if (useCustomList) {
			const listItems = [];
			unorderedList.node.children.forEach((li, index) => {
				if (
					li.type === 'element' &&
					li.tagName === 'li' &&
					li.children.length
				) {
					listItems.push(
						<CustomListItem key={index} value={listItemValue(li.children)} />
					);
				}
			});

			return <ul style={{ listStyle: 'none', padding: 0 }}>{listItems}</ul>;
		}

		return <ul>{unorderedList.children}</ul>;
	},
	table: (table) => {
		const tableProperties = get(table, 'node.properties', {});

		return useCustomTable ? (
			<StyledTableTag
				tableTextAlignment={tableTextAlignment}
				rowBorder={
					typeof rowBorder !== 'boolean'
						? tableProperties.rules === 'rows'
						: rowBorder
				}
				columnBorder={
					typeof columnBorder !== 'boolean'
						? tableProperties.rules === 'cols'
						: columnBorder
				}
				tableStyled={
					typeof tableStyled !== 'boolean'
						? tableProperties.dataStyledTable === 'true'
						: tableStyled
				}
			>
				{table.children}
			</StyledTableTag>
		) : (
			<table {...tableProperties}>{table.children}</table>
		);
	},

	figure: (figure) => {
		const figureProperties = get(figure, 'node.properties', {});
		return (
			<figure {...figureProperties} style={{ margin: 0 }}>
				{figure.children}
			</figure>
		);
	},
	a: (anchor) => (
		<CustomAnchor anchorPoperties={get(anchor, 'node.properties', {})}>
			{anchor.children}
		</CustomAnchor>
	),
});

export default getCustomComponent;
